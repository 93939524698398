@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./fonts.css";
body {
  margin: 0;

  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  body {
    @apply font-Opensans;
  }
  *::-webkit-scrollbar {
    @apply h-1 w-1 border border-BrandBlack block rounded-full;
  }

  *::-webkit-scrollbar-track {
    @apply h-1 w-1 border border-solid border-gray-300 rounded-full;
  }

  *::-webkit-scrollbar-thumb {
    @apply bg-contentcolor rounded-full;
  }
  .tablet-scroll::-webkit-scrollbar {
    @apply w-0 h-0;
  }
  .sidebar-scroll::-webkit-scrollbar {
    @apply h-0 w-0 border border-BrandBlack block rounded-full;
  }
  .flag_css {
    @apply !w-auto;
    position: unset !important;
  }
  .flag_css .flag-dropdown {
    @apply !bg-mainuploader !border-0 !border-r !border-gray-400 !rounded-l-lg !z-[1] m-1px mr-0;
  }
  .flag_css .flag-dropdown.open .selected-flag {
    @apply !rounded-l-lg;
  }
  .flag_css.flag_css_focused .flag-dropdown {
    @apply !border-gray-600;
  }
  .flag_css.flag_css_error .flag-dropdown {
    @apply !border-Darkred;
  }
  input {
    @apply font-Opensans;
  }
}
.react-datepicker-wrapper {
  @apply !block;
}
.react-datepicker-wrapper .react-datepicker__input-container {
  @apply flex items-center gap-2;
}
.react-datepicker-wrapper .react-datepicker__input-container svg {
  @apply ml-2;
}
.react-datepicker-wrapper
  .react-datepicker__input-container
  .react-datepicker__close-icon {
  @apply mr-0;
}
.css-1n6sfyn-MenuList {
  max-height: unset !important;
}
.timesheet_add_entry .css-1n6sfyn-MenuList {
  max-height: 300px !important;
}
.css-be29ww-menu {
  @apply !py-0;
}
.css-1rymgm4-option {
  @apply border-b border-solid border-loginBorder rounded-none !pl-2;
}
.css-1j24gv7-option {
  @apply !pl-2;
}
.jobs-table-td:nth-child(2) {
  min-width: 310px;
}
.truncate2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cs-file-uploader {
  @apply text-white border border-black/20 relative  rounded-lg p-8 w-full h-full before:absolute before:content-[''] before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2  before:w-8 before:h-8;
}
.cs-file-uploader::before {
  background-image: url("data:image/svg+xml,%3Csvg width='23' height='24' viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.464 1.96155V22.1575' stroke='%23858585' stroke-width='2.244' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1.36597 12.0595H21.562' stroke='%23858585' stroke-width='2.244' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: cover;
}
.cs-file-uploader::file-selector-button {
  @apply hidden;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.rbc-header {  
  @apply bg-tableheaderbg items-center justify-center flex !py-3 !text-sm;
}

.rbc-event-content {
  font-weight: 400;
}
.multiple_assign .css-1h9w3iu-control {
  @apply !h-auto !min-h-max;
}
.react-datepicker-popper {
  @apply !z-20;
}